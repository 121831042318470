//import { UserManager, WebStorageStateStore } from 'oidc-client';
import { ApplicationPaths, ApplicationName, roles } from './ApiAuthorizationConstants';
class AuthService {
  _callbacks: any[] = [];
  _nextSubscriptionId = 0;
  _user: any = undefined;
  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;
  //  userManager: UserManager = undefined;
  _checking: Promise<void> | undefined = undefined;

  async isAuthenticated() {
    const user = await this.getUser();
    return !!user;
  }

  async hasClaim(claim: string) {
    const usr = await this.getUser();
    for (const usrclaim of usr?.claims?.split(',') || []) {
      //if (typeof usrclaim == 'string') continue;
      if (claim.toUpperCase() === usrclaim.toUpperCase()) return true;
    }
    return false;
  }

  async isDev() {
    return await this.hasClaim('CanSeeDevWork');
  }

  async claims(): Promise<string[]> {
    const usr = await this.getUser();
    return usr?.claims || [];
  }

  async roles(): Promise<roles[]> {
    const usr = await this.getUser();
    return (usr?.roles || 'Anonymous').split(',');
  }

  async getUser() {
    if (this._user && this._user.profile) {
      return this._user.profile;
    }
    await this.ensureUserManagerInitialized();
    return this && this._user && this._user.profile;
  }

  async init() {
    //console.log('ensureUserManagerInitialized');
    if (this._user !== undefined) {
      return;
    }

    let response = await fetch(ApplicationPaths.AuthOk);
    if (!response.ok) {
      throw new Error(`Could not load settings for '${ApplicationName}'`);
    }

    const settings = await response.json();

    if (!settings || !settings.profile) {
      this._user = {};
      return;
    }
    this._user = { ...settings, claims: settings.profile.claims.split(',') };
  }
  async ensureUserManagerInitialized(): Promise<void> {
    if (this._checking !== undefined) return await this._checking;

    this._checking = this.init();
    await this._checking;
    delete this._checking;
  }

  updateState(user) {
    this._user = user;
    this._isAuthenticated = !!this._user;
    this.notifySubscribers();
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  subscribe(callback) {
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) => (element.subscription === subscriptionId ? { found: true, index } : { found: false }))
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    const ix = subscriptionIndex[0].index || -1;
    this._callbacks.splice(ix, 1);
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect };
  }
  async signIn(state) {
    await this.ensureUserManagerInitialized();

    window.location.href = ApplicationPaths.LoginFull;
    return;
  }

  async completeSignIn(url) {}

  async signOut() {
    await fetch(ApplicationPaths.AuthSignout);
    sessionStorage.clear();
    localStorage.clear();
    this.updateState(undefined);
    //delete this._user;
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthService();

export default authService;

export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail'
};
