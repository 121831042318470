import { Competitions, CachedService } from './types';

class CompetitionService extends CachedService<Competitions> {
  ListCompetitions = async (organisationId: number, eventId: number) =>
    this.Get<Competitions>(`/competitions/list?OrgId=${organisationId}&EventId=${eventId}`);

  async SaveCompetition(toSave: Competitions): Promise<{ id: number; err: string }> {
    var rs = await this.Post<Competitions, Competitions>(`/competitions/save`, toSave);
    if (rs && rs?.competitionId > 0) {
      return { id: rs.competitionId, err: '' };
    } else {
      return { id: -1, err: 'Error saving competition\r\n<br />' };
      //return { id: -1, err: 'Competition Service (new) not yet implemented: \r\n<br />' + JSON.stringify(toSave) };
    }
  }

  async NewCompetition(toSave: Competitions): Promise<{ id: number; err: string }> {
    return this.SaveCompetition(toSave);
  }

  async UpdateCompetition(toSave: Competitions): Promise<{ id: number; err: string }> {
    return this.SaveCompetition(toSave);
  }
}

export const instance = new CompetitionService('ck_cmpTSrv');

export default instance;
