import React from 'react';
import './NavMenu.css';
import { LoginDisplay } from './logindisplay';
import { Nav } from 'react-bootstrap';
import { AuthView } from '../../api-authorization/AuthView';
import { RouterNavLink } from '../shared/Lnk';

//interface props extends RouteComponentProps {}

export class NavMenu extends React.PureComponent {
  static displayName = NavMenu.name;

  render() {
    return (
      <header>
        <nav className="navbar-expand box-shadow mb-3 navbar navbar-expand-xl">
          <div className="container-lg d-flex pt-6">
            <RouterNavLink exact to="/" className="align-middle navbar-brand active">
              <img
                src="img/logos-totf-txt.svg"
                alt="Top On The Floor"
                className="d-xs-inline-block align-middle svg-logo"
              />
            </RouterNavLink>

            <div className="linkGroup ml-auto">
              <Nav.Item className="d-none d-md-block align-self-center ml-auto">
                <RouterNavLink exact to="/">
                  <span>
                    <i className="fa fa-home" aria-hidden="true" title="Home - Events" /> Home
                  </span>
                </RouterNavLink>
              </Nav.Item>
              <Nav.Item className="d-none d-md-block align-self-center">
                <RouterNavLink to="/events">
                  <span className="d-none d-md-block">
                    <i className="fa fa-calendar" aria-hidden="true" title="Events" /> Events
                  </span>
                </RouterNavLink>
              </Nav.Item>
              <Nav.Item className="d-none d-md-block align-self-center">
                <RouterNavLink to="/rewards">
                  <span className="d-none d-md-block">
                    <i className="fa fa-trophy" aria-hidden="true" title="Rewards" /> Rewards
                  </span>
                </RouterNavLink>
              </Nav.Item>

              <Nav.Item className="d-none d-md-block align-self-center">
                <RouterNavLink to="/rules">
                  <span className="d-none d-md-block">
                    <i className="fa fa-info-circle" aria-hidden="true" title="Rewards" /> Rules
                  </span>
                </RouterNavLink>
              </Nav.Item>
              <AuthView claim="ViewConfigurationMenu">
                <Nav.Item className="d-none d-md-block align-self-center">
                  <RouterNavLink exact href="/Identity/Account/Organisations/EditOrganisations">
                    <span className="d-none d-md-block">
                      <i className="fa fa-gear" aria-hidden="true" title="Configuration" /> Configuration
                    </span>
                  </RouterNavLink>
                </Nav.Item>
              </AuthView>
              <LoginDisplay />
            </div>
          </div>
        </nav>
        <nav className="fixed-bottom d-block d-md-none">
          <div className="bottomNav">
            <Nav.Item className="align-self-center">
              <RouterNavLink exact to="/">
                <i className="fa fa-home d-block" aria-hidden="true" title="Home - Events" />
                <span>Home</span>
              </RouterNavLink>
            </Nav.Item>

            <Nav.Item className="align-self-center">
              <RouterNavLink to="/events">
                <i className="fa fa-calendar d-block" aria-hidden="true" title="Events" />
                <span>Events</span>
              </RouterNavLink>
            </Nav.Item>
            <Nav.Item className="align-self-center">
              <RouterNavLink to="/rewards">
                <i className="fa fa-trophy d-block" aria-hidden="true" title="Rewards" />
                <span>Rewards</span>
              </RouterNavLink>
            </Nav.Item>
            <Nav.Item className="align-self-center">
              <RouterNavLink to="/rules">
                <i className="fa fa-info-circle d-block" aria-hidden="true" title="Rewards" />
                <span>Rules</span>
              </RouterNavLink>
            </Nav.Item>
            <AuthView claim="ViewConfigurationMenu">
              <Nav.Item className="d-none d-md-block align-self-center">
                <RouterNavLink exact href="/Identity/Account/Organisations/EditOrganisations">
                  <i className="fa fa-gear d-block" aria-hidden="true" title="Configuration" />
                  <span>Configuration</span>
                </RouterNavLink>
              </Nav.Item>
            </AuthView>

            <LoginDisplay stacked />
          </div>
        </nav>
      </header>
    );
  }
}
