import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import dayjs from 'dayjs';
import { RankingService } from '../../services';

export function Footer({ isDev, claims, roles }) {
  return (
    <>
      <footer className="d-none d-md-flex footer container">
        <div className="copyright">&copy; {dayjs().year()} TOP ON THE FLOOR </div>
        <div className="footerLinks">
          <LinkContainer to="/legal">
            <a href="/legal">Terms and Conditions</a>
          </LinkContainer>
          <span>&nbsp; | &nbsp;</span>
          <a href="mailto:contact@totf.io">Contact us</a>
        </div>
        <div className="social">
          <div>
            <a
              href="https://www.instagram.com/toponthefloor"
              rel="noopener noreferrer"
              target="_blank"
              className="followUs"
            >
              FOLLOW US <i className="fa fa-instagram" aria-hidden="true" />
            </a>
          </div>
        </div>
      </footer>
      <footer className="d-flex d-md-none footer container mobileVersion">
        <div className="social">
          <div>
            <a
              href="https://www.instagram.com/toponthefloor"
              rel="noopener noreferrer"
              target="_blank"
              className="followUs"
            >
              FOLLOW US <i className="fa fa-instagram" aria-hidden="true" />
            </a>
          </div>
        </div>

        <div className="footerLinks">
          <LinkContainer to="/legal">
            <a href="/legal">Terms and Conditions</a>
          </LinkContainer>
          <span>&nbsp; | &nbsp;</span>
          <a href="mailto:contact@totf.io">Contact us</a>
        </div>
        <div className="copyright">&copy; {dayjs().year()} TOP ON THE FLOOR </div>
      </footer>

      {isDev && claims && (
        <div className="devBox">
          <br />
          <br />
          <button onClick={RankingService.TestDumbStuff}>Test Messagepack</button>
          <span className="text-muted text-wrap">Claims: {claims}</span>
          <span className="text-muted text-wrap">Roles: {roles}</span>
        </div>
      )}
    </>
  );
}
