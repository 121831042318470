import * as React from 'react';

import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from 'react-bootstrap';
//import { RouteComponentProps } from 'react-router-dom';
// between react-router-dom, react-router and react-router-bootstrap, it's getting a little tricky to Generate the right items.

//export class Lnk extends React.Component {
//    render() {

//    }
//}

const RouterNavLink = ({ children, ...props }) =>
  'to' in props ? (
    <LinkContainer {...props}>
      <Nav.Link active={false}>{children}</Nav.Link>
    </LinkContainer>
  ) : (
    <LinkContainer {...props} to={props.href}>
      <Nav.Link active={false}>{children}</Nav.Link>
    </LinkContainer>
  );

const Link = ({ children, ...props }) => <LinkContainer {...props}></LinkContainer>;

export { RouterNavLink, Link, RouterNavLink as Lnk };

export default RouterNavLink;
