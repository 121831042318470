import { PureComponent } from 'react';
/* eslint react/react-in-jsx-scope: "off" */
export class Register extends PureComponent {
  render() {
    return (
      <main role="main" className="pb-3">
        <h1>Register</h1>

        <div className="row">
          <div className="col-md-4">
            <form method="post" action="/Identity/Account/Register" noValidate={true}>
              <h4>Create a new account.</h4>
              <hr />
              <div className="text-danger validation-summary-valid" data-valmsg-summary="true">
                <ul>
                  <li style={{ display: 'none' }}></li>
                </ul>
              </div>
              <div className="form-group">
                <label htmlFor="Input_Email">Email</label>
                <input
                  className="form-control valid"
                  type="email"
                  data-val="true"
                  data-val-email="The Email field is not a valid e-mail address."
                  data-val-required="The Email field is required."
                  id="Input_Email"
                  name="Input.Email"
                  value=""
                  aria-describedby="Input_Email-error"
                  aria-invalid="false"
                />
                <span
                  className="text-danger field-validation-valid"
                  data-valmsg-for="Input.Email"
                  data-valmsg-replace="true"
                ></span>
              </div>
              <div className="form-group">
                <label htmlFor="Input_Password">Password</label>
                <input
                  className="form-control valid"
                  type="password"
                  data-val="true"
                  data-val-length="The Password must be at least 6 and at max 100 characters long."
                  data-val-length-max="100"
                  data-val-length-min="6"
                  data-val-required="The Password field is required."
                  id="Input_Password"
                  maxLength={100}
                  name="Input.Password"
                  aria-describedby="Input_Password-error"
                  aria-invalid="false"
                />
                <span
                  className="text-danger field-validation-valid"
                  data-valmsg-for="Input.Password"
                  data-valmsg-replace="true"
                ></span>
              </div>
              <div className="form-group">
                <label htmlFor="Input_ConfirmPassword">Confirm password</label>
                <input
                  className="form-control"
                  type="password"
                  data-val="true"
                  data-val-equalto="The password and confirmation password do not match."
                  data-val-equalto-other="*.Password"
                  id="Input_ConfirmPassword"
                  name="Input.ConfirmPassword"
                />
                <span
                  className="text-danger field-validation-valid"
                  data-valmsg-for="Input.ConfirmPassword"
                  data-valmsg-replace="true"
                ></span>
              </div>
              <button type="submit" className="btn btn-primary">
                Register
              </button>
              <input
                name="__RequestVerificationToken"
                type="hidden"
                value="CfDJ8L86oq9k805GreE3W95e6j1g823WhFb0Rs8BP7ptIDaGzZ2KlzG0P34t4wvVC8q-oUC7PA_Vx7YUei9XcumqPdnp3w6g5O2H2tcgZYKoS9ixgea0yhjbH66KmpAEiRNf60J0TNZ4s9F9iedIYN2Nn3Y"
              />
            </form>
          </div>
          <div className="col-md-6 col-md-offset-2">
            <section>
              <h4>Use another service to register.</h4>
              <hr />
              <form
                id="external-account"
                method="post"
                className="form-horizontal"
                action="/Identity/Account/ExternalLogin"
              >
                <div>
                  <p>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      name="provider"
                      value="Google"
                      title="Log in using your Google account"
                    >
                      Google
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      name="provider"
                      value="Facebook"
                      title="Log in using your Facebook account"
                    >
                      Facebook
                    </button>
                  </p>
                </div>
              </form>
            </section>
          </div>
        </div>
      </main>
    );
  }
}
