import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './Router';
import * as serviceWorker from './serviceWorker';
import dayjs from 'dayjs';
import TagManager from 'react-gtm-module';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? '';
const rootElement = document.getElementById('root');
/*
 * probably need to link router with these tags:
 * https://measureschool.com/track-single-page-applications-gtm/
 * https://www.analyticsmania.com/post/single-page-web-app-with-google-tag-manager/
 * https://www.analyticsmania.com/post/virtual-pageviews-multiple-history-events/
 * https://analytics.google.com/analytics/web/#/p247361902/realtime/overview?params=_u..nav%3Ddefault%26_u..pageSize%3D25
 * */
const tagManagerArgs = { gtmId: 'GTM-NH7G266' };
TagManager.initialize(tagManagerArgs);

async function LoadScript(url: string, async: boolean = false, crossOrigin?: string) {
  if (url.endsWith('.css')) {
    const s = document.createElement('link');
    s.relList.add('stylesheet', 'preload');
    s.as = 'style';
    //s.async = async;
    s.type = 'text/css';
    s.href = url;
    if (crossOrigin) s.crossOrigin = crossOrigin;
    document.body.appendChild(s);
  } else if (url.endsWith('.js')) {
    const s = document.createElement('script');
    s.async = async;
    s.src = url;
    if (crossOrigin) s.crossOrigin = crossOrigin;
    document.body.appendChild(s);
  }
}
async function LoadScripts(urls: string[]) {
  return Promise.all(urls.map((url) => LoadScript(url))); //'https://use.fontawesome.com/3911eaaa9d.js')
}

//<link href="css/sp_bootstrap.css" rel="stylesheet" />
LoadScripts([
  //   '/Identity/css/site.css',
  '/css/sweetpunk.min.css',
  '/css/bootstrap-multiselect.css',
  'https://use.fontawesome.com/3911eaaa9d.js',
  //'/Identity/lib/jquery/dist/jquery.min.js',
  //'/Identity/lib/bootstrap/dist/js/bootstrap.bundle.min.js',
  //'/Identity/js/site.js',
  'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/js/bootstrap-datepicker.min.js',
  'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js'
]).then(() => {
  //window.navigator.userLanguage ||
  var locale = window.navigator.language;
  //console.log('Setting date local to ' + locale);
  dayjs.locale(locale);

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter basename={baseUrl}>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    rootElement
  );
});

serviceWorker.unregister();
//serviceWorker.register();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();

/*
 <script src="~/Identity/lib/jquery/dist/jquery.min.js"></script>
        <script src="~/Identity/lib/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
        <script src="~/Identity/js/site.js" asp-append-version="true"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/js/bootstrap-datepicker.min.js"></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
          integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1"
          crossorigin="anonymous"
        ></script>
*/
