import { isDate } from 'util/types';
import { CachedService, RankingInfo, PrizeTotals, DancersWithRank } from '../components/admin/services';
//import { Awards, CachedService, Categories, Disciplines } from '../components/admin/services/types';

import { decode, decodeAsync } from '@msgpack/msgpack';

let globalmappingData: PropResult[];

var task = fetch('/ranking/mappingdata', {
  headers: { 'Accept-Encoding': 'gzip, deflate, br' },
  method: 'get'
})
  .then((rs) => rs.json())
  .then((o) => {
    globalmappingData = o;
  });

const sortAndDistinct = (years: number[]) => {
  const distinctYears = new Set<number>();
  if (years?.length) {
    for (const year of years) {
      distinctYears.add(year);
    }
  }

  var seasarr = Array.from(distinctYears);
  seasarr.sort().reverse();
  //seasarr.splice(0, 0, -1);
  return seasarr;
};

const propMap: (keyof DancersWithRank)[] = [
  'rank',
  'points',
  'prevRank',
  'prevPoints',

  'industryPoints',
  'industryRank',
  'prevIndustryRank',
  'prevIndustryPoints',

  'dancerId',
  'dancerPseudonym',
  'aspNetUsersId',
  'nationality',
  'creationDate',
  'modificationDate',
  'eReputationScore',
  'profilePhoto',
  'gender',
  'status',
  'dancerDescription',
  'dateOfBirth',
  'globalPoints',
  'height',
  'weight',
  'shoeSize',
  'clothing',
  'dancersCrew',
  'dancerAffiliateCode',
  'dancerCompetitionPosition',
  'dancerExperiences',
  'dancerSeasonInfo',
  'deceasedDate',
  'ripmessage',
  'rankingDancerEventDetails',
  'rankingDancerDetails',
  'position',
  'facebookLogin',
  'instagramLogin',
  'twitterLogin',
  'tiktokLogin'
];

const valueMap = {
  100000: 'Ĉ',
  50000: 'ª'
};

const invValueMap = {};

const dateIndicies = [
  propMap.indexOf('creationDate'),
  propMap.indexOf('modificationDate'),
  propMap.indexOf('dateOfBirth'),
  propMap.indexOf('deceasedDate')
];

for (const kvp of Object.entries(valueMap)) {
  invValueMap[kvp[1]] = kvp[0];
} // inverse map the above map.

function shrinkDancer(d: DancersWithRank) {
  const rv: any[] = [];
  let undefinedMap = 0;
  let nullMap = 0;
  let emptyArrayMap = 0;
  let ix = -1;

  for (const key of propMap) {
    ++ix;
    let value = d[key];
    if (value === undefined) {
      undefinedMap |= 1 << ix;
      continue;
    }
    if (value === null) {
      nullMap |= 1 << ix;
      continue;
    }

    if (Array.isArray(value) && value.length === 0) {
      emptyArrayMap |= 1 << ix;
      continue;
    }

    if (dateIndicies.indexOf(ix) > -1) {
      rv.push(Date.parse(value as string));
      continue;
    }

    if (typeof value === 'string') {
      if (!!valueMap[value]) {
        rv.push(valueMap[value]);
        continue;
      }
      value = value.replace('https://iscardwi.sirv.com/Images/dancers/', 'þ').replace('https://zupimages.net/up/', 'Þ');
    }

    if (typeof value === 'number') {
      if (!!valueMap[value]) {
        rv.push(valueMap[value]);
        continue;
      }
    }
    rv.push(value);
  }
  rv.splice(0, 0, undefinedMap, nullMap, emptyArrayMap);
  return rv;
}
function growDancer(d: any): DancersWithRank {
  const rv = {};

  const undefinedMap = d[0];
  const nullMap = d[1];
  const emptyArrayMap = d[2];
  let ix = -1;
  for (const key of propMap) {
    ++ix;
    if (((1 << ix) & undefinedMap) !== 0) {
      continue; // field was undefined
    }
    if (((1 << ix) & nullMap) !== 0) {
      rv[key] = null;
      continue; // field was undefined
    }
    if (((1 << ix) & emptyArrayMap) !== 0) {
      rv[key] = [];
      continue; // field was undefined
    }

    let value = d[ix];

    if (dateIndicies.indexOf(ix) > -1) {
      rv[key] = new Date(value); //.push(Date.parse(value as string));
      continue;
    }

    if (typeof value === 'string') {
      if (!!invValueMap[value]) {
        rv[key] = invValueMap[value];
        continue;
      }
      value = value.replace('þ', 'https://iscardwi.sirv.com/Images/dancers/').replace('Þ', 'https://zupimages.net/up/');
    }

    if (typeof value === 'number') {
      if (!!invValueMap[value]) {
        rv[key] = invValueMap[value];
        continue;
      }
    }

    rv[key] = value;
  }

  return rv as any as DancersWithRank;
}

interface PropResult {
  name: string;
  propertyType: string;
  mpKey: number;
}

function mpExpander(d: []): DancersWithRank {
  const rv = {};

  for (const key of globalmappingData) {
    if (key.mpKey === -1) continue;
    let value: any = d[key.mpKey];
    if (!value) {
      if (key.name == 'eReputationScore') {
        rv[key.name] = 0;
      }
      continue;
    }
    if (value instanceof Date) {
      let dateStr = value.toISOString();
      dateStr = dateStr.substr(0, dateStr.length - 1);
      rv[key.name] = dateStr;
      continue;
    }
    rv[key.name] = value;
  }

  return rv as any as DancersWithRank;
}

class RankingEventService extends CachedService<RankingInfo> {
  //ListTop = async (forceRefresh?: boolean) => await this.GetCached('/ranking/top100', forceRefresh);

  GetAvailableSeasons = async (forceRefresh?: boolean) =>
    await this.GetCachedOf<number[]>('/ranking/seasonList', forceRefresh, sortAndDistinct);

  ListTopForSeason_old = async (season: number, forceRefresh?: boolean) =>
    await this.GetCachedProcessed('/ranking/top100/' + season, true, this.shrinkRankingInfo, this.growRankingInfo);

  ListTopForSeason = async (season: number, forceRefresh?: boolean) =>
    await this.GetCachedProcessed(
      '/ranking/top100_s/' + season,
      forceRefresh || true,
      (c) => c,
      this.growRankingInfo_s,
      false,
      false
    );

  TestDumbStuff = async () => {
    ////var mappingData =
    //if (!globalmappingData) {
    //  var rs = await this.GetCachedOf<PropResult[]>('/ranking/mappingdata', true);
    //  if (rs) {
    //    globalmappingData = rs;
    //    //globalmappingData ||= (mappingData!;
    //  }
    //}
    //var response = await fetch('https://toponthefloor.local:25001/ranking/top100_s/2023', {
    //  headers: {
    //    accept: '*/*',
    //    'accept-language': 'en-US,en;q=0.9',
    //    'accept-encoding': 'br'
    //  },
    //  referrer: 'https://toponthefloor.local:25000/',
    //  referrerPolicy: 'strict-origin-when-cross-origin',
    //  body: null,
    //  method: 'GET',
    //  mode: 'cors',
    //  credentials: 'include'
    //});
    //console.log(response);
    //const MSGPACK_TYPE = 'application/x-msgpack';
    //const contentType = response.headers.get('Content-Type');
    //if (contentType && contentType.startsWith(MSGPACK_TYPE) && response.body != null) {
    //  const object = (await decodeAsync(response.body)) as any[];
    //  console.dir(object);
    //  const ri = object[0];
    //  const otherRI = (await this.ListTopForSeason(2023))[0];
    //  console.log(otherRI);
    //  let diffs = 0;
    //  for (let i = 0; i < otherRI.industry.length && diffs < 100; i++) {
    //    let o = otherRI.industry[i];
    //    let n = newt.industry[i];
    //    for (let prop of Object.keys(o)) {
    //      const ov: any = o[prop];
    //      const nv: any = n[prop];
    //      if (ov != nv) {
    //        if (Array.isArray(ov) && ov.length == 0) {
    //          continue; // fine, empty array
    //        }
    //        if (!nv && !ov) {
    //          //emptystring vs null/undefined
    //          continue;
    //        }
    //        if (/\d{4}-\d{2}-\d{2}T/.test(ov)) {
    //          if (ov.length != nv.length && ov.substring(0, 10) == nv.substring(0, 10)) {
    //            // dates are close enough;
    //            continue;
    //          }
    //        }
    //        //debugger;
    //        diffs++;
    //        console.log(`ix: ${i}, prop:${prop}; o: ${ov}, n: ${nv}`);
    //        console.log(n);
    //        console.log(o);
    //      }
    //    }
    //  }
    //  console.log(`Rows ${otherRI.street.length}, diffs:${diffs}`);
    //} else {
    //  console.log('no idea wtf happened.');
    //}
    //// do something with object
    ////const object = await decodeAsync(rs.body);
    ////var b = await rs.blob();
    ////var dd = await decodeFromBlob(b);
    ////console.log(dd);
  };

  GetTotalPrizeMoney = async (season: number, forceRefresh?: boolean) =>
    await this.GetCachedOf<PrizeTotals>('/ranking/prizetotal/' + season, forceRefresh);

  growRankingInfo_s = (ri: any): RankingInfo => {
    let discDancerSet = ri[4];
    if (discDancerSet) {
      for (let k of Object.keys(discDancerSet)) {
        discDancerSet[k] = new Set(discDancerSet[k]);
      }
    }

    return {
      totalStreetDance: ri[0],
      totalDanceIndustry: ri[1],
      street: ri[2].map(mpExpander),
      industry: ri[3].map(mpExpander),
      disciplineDancers: discDancerSet
    };
  };

  shrinkRankingInfo = (ri: RankingInfo) => {
    return {
      industry: ri.industry.map(shrinkDancer),
      street: ri.street.map(shrinkDancer),
      totalDanceIndustry: ri.totalDanceIndustry,
      totalStreetDance: ri.totalStreetDance
    };
  };

  growRankingInfo = (ri: any): RankingInfo => {
    return {
      industry: ri.industry.map(growDancer),
      street: ri.street.map(growDancer),
      totalDanceIndustry: ri.totalDanceIndustry,
      totalStreetDance: ri.totalStreetDance
    } as any as RankingInfo;
  };
}

export const instance = new RankingEventService('ck_ranking');

export default instance;
