import * as React from 'react';
import { Fragment } from 'react';
import { roles } from './ApiAuthorizationConstants';
import AuthService from './AuthService';

interface state {
  loaded: boolean;
  auth: boolean;
  user?: any;
}
interface props {
  role?: roles;
  claim?: string;
  claims?: string[];
  roles?: roles[];
}

function IsInThere(needles, haystack) {
  let found = false;
  for (const needle of needles) {
    if (haystack.indexOf(needle) !== -1) {
      found = true;
      break;
    }
  }
  return found;
}

//TODO: make this actually check auth
export class AuthView extends React.PureComponent<props, state> {
  constructor(props) {
    super(props);
    this.state = { loaded: false, auth: false };
  }
  componentDidMount() {
    AuthService.isAuthenticated().then(async (f) =>
      this.setState({ auth: f, loaded: true, user: await AuthService.getUser() })
    );
    AuthService.subscribe(() => {
      this.setState({ auth: AuthService._isAuthenticated });
    });
  }
  render() {
    if (!this.state.loaded) return <Fragment />;
    const { role, claim, roles, claims } = { ...this.props };

    if (!this.state.auth) {
      if (role || !!roles) {
        if (role === 'Anonymous') return <Fragment>{this.props.children}</Fragment>;
        if (roles && roles.indexOf('Anonymous') > -1) return <Fragment>{this.props.children}</Fragment>;
      }

      return <Fragment />;
    }

    if (claims || claim) {
      const needles = (claims || [claim]).map((g) => g?.toLowerCase());
      const haystack = (this.state.user.claims || 'Anonymous').toLowerCase();
      if (!IsInThere(needles, haystack)) {
        return <Fragment />;
      }
    }

    if (roles || role) {
      const needles = (roles || [role]).map((g) => g?.toLowerCase());
      const haystack = (this.state.user.roles || 'Anonymous').toLowerCase();
      if (!IsInThere(needles, haystack)) {
        return <Fragment />;
      }

      if (!IsInThere(needles, haystack)) {
        return <Fragment />;
      }
    }

    return <Fragment>{this.props.children}</Fragment>;
  }
}
