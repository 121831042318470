import dayjs from 'dayjs';

export {
  disciplineServiceInstance as DisciplineService,
  battlePointServiceInstance as AwardsService,
  categoryServiceInstance as CategoriesService,
  currencyServiceInstance as CurrencyService,
  dancerServiceInstance as DancerService,
  rewardsServiceInstance as RewardService
} from './dataServices';

export const thisYear = dayjs().year();
const years: number[] = [];
for (var i = 2021; i <= thisYear; i++) {
  years.push(i);
}
export const seasonArray = years;

export const groupBy = function <T, K extends number | string>(
  xs: T[],
  keyMethod: (itm: T) => number | string
): Record<K, T[]> {
  var rs = {} as Record<K, T[]>;
  for (const itm of xs) {
    var key = keyMethod(itm);
    //var xx = itm[key];

    rs[key] = rs[key] || [];
    rs[key].push(itm);
  }
  return rs;
};

//export const spaceFormat = function (number: number) {
//  //return number.toLocaleString('fr-FR', { useGrouping: true });
//  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
//};

export function spaceThousands(num?: number, includeCents: boolean = false): string {
  if (!num) {
    return '';
  }

  const str = num.toString();
  const output: string[] = [];
  let ix = 1;
  for (var i = str.length; i-- > 0; ix++) {
    if (str.charAt(i) === '.' && !includeCents) {
      // we are going right to left, so if we find a decimal point, start over..
      ix = 0;
      output.splice(0, output.length); // clear the array;
      continue;
    }
    output.push(str.charAt(i));
    if (ix % 3 === 0) output.push(' ');
  }
  return output.reverse().join('');
}

//export const groupBy = function <T, K extends number | string>(
//  xs: T[],
//  keyMethod: (itm: T) => K
//): { key: K; value: T[] }[] {
//  var rs = {} as Record<K, T[]>;
//  var Returned = new Set<string | number>();
//  for (const itm of xs) {
//    var key = keyMethod(itm);

//    rs[key] = rs[key] || [];
//    rs[key].push(itm);
//  }
//  const rv: { key: K; value: T[] }[] = [];
//  for (const itm of xs) {
//    var key = keyMethod(itm);
//    if (!Returned.has(key)) {
//      rv.push({ key: key, value: rs[key] });
//      Returned.add(key);
//    }
//  }

//  return rv;
//};

export { instance as cSrv } from './countryService';
export { instance as EventService } from './eventService';
export { instance as RankingService } from './rankingService';
