//import { asTask, Task } from '../components/shared/Task';

export interface Country {
  id: number;
  iso: string;
  name: string;
  nicename: string;
  iso3: string;
  numcode: string;
  phonecode: string;
}

class CountryService {
  //static state: Country[];

  static Countries: { [iso: string]: Country };
  static loading: boolean;

  static Loaded: Promise<Country[]>;
  static IsLoaded: boolean = false;

  static resolver() {}

  countries = () => CountryService.Countries;

  constructor() {
    this.retrieve();
    //if (!CountryService.Loaded) CountryService.Loaded = new Promise(() => {});
  }

  store(countries: Country[]) {
    const map: { [iso: string]: Country } = {};
    for (let c of countries) {
      map[c.iso.toLowerCase()] = c;
    }
    CountryService.Countries = map;

    localStorage.setItem('countryListMap', JSON.stringify(map));
    //CountryService.state = countries;
  }

  retrieve() {
    const cache = localStorage.getItem('countryListMap');
    if (cache) {
      CountryService.Countries = JSON.parse(cache) as { [iso: string]: Country };
    }
  }

  async List(): Promise<Country[]> {
    if (!!CountryService.Countries) {
      const rv: Country[] = [];
      for (const country of Object.values(CountryService.Countries)) {
        rv.push(country);
      }
      return rv;
    }

    /*
        while (CountryService.loading) {
              await new Promise((resolve) => setTimeout(resolve, 250));
              //await setTimeout(() => {}, 250);
            }
        */
    if (!CountryService.Loaded) {
      CountryService.Loaded = new Promise(async (resolve, reject) => {
        CountryService.loading = true;
        try {
          //const obj = await asTask(
          const obj = await fetch('/country/list')
            .then((rs) => rs.json())
            .then((js) => js as Country[]);

          this.store(obj);
          resolve(obj);
          //return obj;
        } finally {
          CountryService.loading = false;
        }
        reject('boom');
      });
    }
    return await CountryService.Loaded;
  }
}

export const instance = new CountryService();
export default instance;
