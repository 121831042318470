import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.css';
import { Layout } from './components/layout/layout';

import AuthorizeRoute from './api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import { Register } from './api-authorization/Register';

import { Spinner } from 'react-bootstrap';

//////////////// Pages

const RankingPage = React.lazy(() => import('./components/RankingPage'));
const CacheClearPage = React.lazy(() => import('./components/CacheClearPage'));
const EventsPage = React.lazy(() => import('./components/EventsPage'));
const OrganisationsPage = React.lazy(() => import('./components/admin/OrganisationsPage'));
const SpecificEventPage = React.lazy(() => import('./components/SpecificEventPage'));
const DancerProfilePage = React.lazy(() => import('./components/DancerProfilePage'));
//const RankingPage = React.lazy(() => import('./components/RankingPage'));
const PrivacyPage = React.lazy(() => import('./components/PrivacyPage'));
const RewardsPage = React.lazy(() => import('./components/RewardsPage'));
const RulesPage = React.lazy(() => import('./components/RulesPage'));

function App() {
  return (
    <Layout>
      <Suspense fallback={<Spinner animation="border" />}>
        <Switch>
          <AuthorizeRoute
            exact
            path="/Identity/Account/Organisations/EditOrganisations"
            render={(props) => {
              return <OrganisationsPage {...props} />;
            }}
          />

          <Route exact path="/" render={(rt) => <RankingPage {...rt} />} />
          <Route exact path="/news" render={(rt) => <RankingPage {...rt} />} />

          <Route exact path="/events" render={() => <EventsPage />} />
          <Route path="/events/:eventId" render={(rt) => <SpecificEventPage {...rt} />} />
          <Route path="/dancers/:dancerId" render={(rt) => <DancerProfilePage {...rt} />} />
          <Route exact path="/legal" render={() => <PrivacyPage />} />
          <Route exact path="/rules" render={() => <RulesPage />} />
          <Route exact path="/rewards" render={() => <RewardsPage />} />

          <Route exact path="/clearLocalCache" render={(rt) => <CacheClearPage {...rt} />} />

          <Route exact path="/register" component={Register} />
          <Route path={ApplicationPaths.ApiAuthorizationPrefix} render={() => <ApiAuthorizationRoutes />} />

          <Route
            path="*"
            render={(props) => {
              console.log('Route unknown!');
              return <div>Route not found: {JSON.stringify(props.location.pathname)}</div>;
            }}
          />
        </Switch>
      </Suspense>
    </Layout>
  );
}

export default App;
