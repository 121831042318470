import React from 'react';
import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';
import { Spinner } from 'react-bootstrap';
//import authService from './AuthorizeService'
import authService from './AuthService';

export default class AuthorizeRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated } = this.state;
    var link = document.createElement('a');
    link.href = this.props.path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;
    if (!ready) {
      return (
        <div>
          <Spinner animation="border" />
        </div>
      );
    } else {
      const { component: Component, ...rest } = this.props;
      if (this.props.render) {
        return (
          <Route
            {...rest}
            render={(props) => {
              if (authenticated) {
                return this.props.render(); // <Component {...props} />;
              } else {
                return <Redirect to={redirectUrl} />;
              }
            }}
          />
        );
      } else {
        return (
          <Route
            {...rest}
            render={(props) => {
              if (authenticated) {
                return <Component {...props} />;
              } else {
                return <Redirect to={redirectUrl} />;
              }
            }}
          />
        );
      }
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ ready: true, authenticated });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }
}
