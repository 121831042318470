import {
  BattlePoints,
  CachedService,
  Categories,
  Disciplines,
  Currencies,
  Dancers,
  DancerBattleInfo,
  Rewards,
  RewardInformation,
  jsonPostOptions
} from '../components/admin/services/types';

class DisciplineService extends CachedService<Disciplines> {
  ListDisciplines = async (forced?: boolean) => {
    const d = await this.GetCached(`/disciplines/list`, forced);
    //const DefaultDiscipline: Disciplines = { discipline: '<All Disciplines>' };
    d.unshift({ discipline: '<All>', id: -1, competitions: [] });
    //let ix = d.findIndex((itm) => itm.discipline == 'All Style');
    //d.splice(ix, 1);
    return d;
    //return d;
  };
}
export const disciplineServiceInstance = new DisciplineService('ck_disciplines');

class BattlePointService extends CachedService<BattlePoints> {
  ListBattlePoints = async (forced?: boolean) => await this.GetCached(`/awards/list`, forced);

  // returns the inserted id
  AddBattlePoints = async (newBattlepoints: BattlePoints) =>
    await this.Post<number, BattlePoints>(`/battlepoints/new`, newBattlepoints);
}
export const battlePointServiceInstance = new BattlePointService('ck_awards');

class CategoryService extends CachedService<Categories> {
  ListCategories = async (forced?: boolean) => await this.GetCached(`/categories/list`, forced);
}
export const categoryServiceInstance = new CategoryService('ck_categories');

class CurrencyService extends CachedService<Currencies> {
  ListCurrencies = async (forced?: boolean) => await this.GetCached(`/currencies/list`, forced);
}
export const currencyServiceInstance = new CurrencyService('ck_currencies');

class DancerService extends CachedService<Dancers> {
  DeleteDancer = async (dancer: Dancers) => {
    if (!dancer || !dancer.dancerId) return false;

    try {
      await fetch('/dancers/delete', {
        ...jsonPostOptions,
        redirect: 'error',
        body: JSON.stringify(dancer)
      });

      return true;
    } catch (err) {
      return false;
    }
  };
  DancerDetails = async (dancerId: number | undefined) => {
    if (!dancerId) return undefined;

    return await this.Post<Dancers, number>(`/dancers/details`, dancerId);
  };
  GetAffiliateCounts = async (dancerId: number) => {
    return (await this.Get<{ item1: number; item2: number }>(`/dancers/seasonaffiliatecounts/${dancerId}`)).map(
      (tup) => {
        return {
          seasonStart: tup.item1,
          count: tup.item2
        };
      }
    );
  };

  GetBattleInfo = async (dancerId: number) => {
    return await this.Get<DancerBattleInfo>(`/dancers/battlesummary/${dancerId}`);
  };

  AddDancer = async (dancer: Dancers) => await this.Post<Dancers, Dancers>(`/dancers/new`, dancer);
  SaveDancer = async (dancer: Dancers) => await this.Post<Dancers, Dancers>(`/dancers/save`, dancer);

  ListDancers = async (forced?: boolean) => await this.Post<Dancers[], string>(`/dancers/search`, '');
  SearchDancers = async (searchText: string) => await this.Post<Dancers[], string>(`/dancers/search`, searchText);
}
export const dancerServiceInstance = new DancerService('ck_dancers');

class RewardsService extends CachedService<Rewards> {
  first = true;

  List = async (season: number, forceRefresh: boolean = false) => {
    var refresh = forceRefresh || this.first;
    var rs = await this.GetCached(`/rewards/list/${season}`, refresh);
    this.first = false;
    return rs;
  };

  GroupedView = async (season: number, forceRefresh: boolean = false) => {
    var refresh = forceRefresh || this.first;
    var rs = await this.GetCachedOf<RewardInformation>(`/rewards/grouped/${season}`, refresh);
    this.first = false;
    return rs;
  };
}
export const rewardsServiceInstance = new RewardsService('ck_rewards');
