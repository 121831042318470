import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { NavMenu } from './TopNavMenu';

import AuthService from '../../api-authorization/AuthService';
import { Footer } from './footer';
import dayjs from 'dayjs';

interface state {
  isDev?: boolean;
  claims?: string[];
  roles?: string[];
}

const cacheBusterString = dayjs().format('YYYYMMDDTHH');
export class Layout extends Component<{}, state> {
  static displayName = Layout.name;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    AuthService.isDev().then((v) => this.setState({ isDev: v }));
    AuthService.claims().then((claims) => this.setState({ claims: claims }));
    AuthService.roles().then((roles) => this.setState({ roles: roles }));
  }

  render() {
    const { isDev, claims, roles } = this.state;

    return (
      <>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.9.0/css/bootstrap-datepicker.min.css"
        />
        <link
          rel="stylesheet preload"
          as="style"
          href={'/css/sweetpunk.min.css?' + cacheBusterString}
          type="text/css"
        />

        <NavMenu />
        <main>
          <Container>{this.props.children}</Container>
        </main>
        <Footer isDev={isDev} claims={claims} roles={roles} />
      </>
    );
  }
  // meaningless change to associate TOTF-107 with the commit
}
