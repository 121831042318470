//export interface CompetitionAward {
//    competitionId: number;
//    awardId: number;
//    award?: Awards;
//    competition?: Competitions;
//    invalid?: boolean;
//}

import { decode, decodeAsync } from '@msgpack/msgpack';

export interface Categories {
  categoryId: number;
  categoryName: string;
  competitions: Competitions[];
}

type Guid = string;

export interface Transaction {
  transactionId: string;
  date: Date;
  delay: string;
  netAmount: number;
  feeAmount: number;
}
export interface PaymentInfo {
  lastCheck: Date;
  lastPaypalUpdate: Date;
  utcNow: Date;
  timeSinceLastCheck: string;
  timeSinceLastPaypalUpdate: string;
  transactions: Transaction[];
}
export interface DancerExperiences {
  experienceId: number;
  dancerId: number;
  experienceStartDate: Date;
  experienceEndDate?: Date | null;
  experienceTitle?: string;
  experienceDescription?: string;
  eventDigitalStrength?: number;
  eventStature?: number;
  artistShowProdDigitalStrength?: number;
  artistShowProdStature?: number;
  experienceTotalPoints?: number;
  dancer?: Dancers;
}

export interface RankingDancerDetails {
  dancerId: number;
  seasonStart: number;
  creationDate: Date;
  sddancerSocialMedia: number;
  // is the totla sum of all events
  sddancerRankingOnTheEvent: number;
  didancerSocialMedia: number;
  didigitalStrengthOfTheShow: number;
  distatureOfTheShow: number;
  didigitalStrengthOfTheArtistOrShowProducer: number;
  distatureOfTheArtistOrShowProducer: number;
}
export interface RankingDancerEventDetails {
  dancerId: number;
  seasonStart: number;
  eventId: number;
  creationDate: Date;
  sddancerRankingOnTheEvent: number;
  sddancerParticipationOnTheEvent: number;
  sdwelcomingCapacityOfTheEventNeb: number;
  sdwelcomingCapacityOfTheEventEbo: number;
  sddigitalStrengthOfTheEvent: number;
  sdstatureOfTheEventNeb: number;
  sdstatureOfTheEventEbo: number;
  sdeventPoints: number;

  sdyearsExistanceNeb?: number;
  sdyearsExistanceEbo?: number;
  sddifficultyEventNeb?: number;
  sddifficultyEventEbo?: number;
  sdnumberClassicBattles?: number;
}

export interface DancerSeasonInfo {
  dancerId: number;
  seasonStart: number;
  facebookLogin: string;
  instagramLogin: string;
  twitterLogin: string;
  tiktokLogin: string;
  globalRankingStreetDance?: number | null;
  globalPointsStreetDance?: number | null;
  globalRankingDanceIndustry?: number | null;
  globalPointsDanceIndustry?: number | null;
  dancersLeagueStreetDance?: string;
  dancersLeagueDanceIndustry?: string;
  socialMediaScore?: number | null;
  toTffansCount?: number | null;
  toTfbackersCount?: number | null;
  businessProviderRate?: number | null;
  globalRankingStreetDancePrev?: number | null;
  globalPointsStreetDancePrev?: number | null;
  globalRankingDanceIndustryPrev?: number | null;
  globalPointsDanceIndustryPrev?: number | null;
  creationDate: Date;
  modificationDate: Date;
  dancer: Dancers;
}

export interface DancerBattleInfo {
  positionAchieved?: number;
  calculatedScore?: number | null;
  displayName?: string;
  eventName?: string;
  competitionId?: number;
  disciplineId?: number;
  categoryId?: number;
  seasonStart: number;
  eventId: number;
}
export interface RankingInfo {
  totalStreetDance: number;
  totalDanceIndustry: number;
  street: DancersWithRank[];
  industry: DancersWithRank[];
  disciplineDancers: { [key: number]: Set<number> };
}

export interface PrizeTotals {
  total: number;
  streetDanceTotal: number;
  danceIndustryTotal: number;
}
export interface DancersWithRank extends Dancers {
  rank?: number;
  points?: number;
  prevRank?: number;
  prevPoints?: number;

  industryPoints?: number;
  industryRank?: number;
  prevIndustryRank?: number;
  prevIndustryPoints?: number;
}
export interface Dancers {
  dancerId: number;
  dancerPseudonym: string;
  aspNetUsersId: Guid | null;
  nationality: string;
  creationDate: Date;
  modificationDate: Date;
  eReputationScore: number | null;
  profilePhoto: string;
  gender: number | null;
  status: number | null;
  dancerDescription?: string;
  dateOfBirth?: Date;
  globalPoints: number;
  height?: number;
  weight?: number;
  shoeSize?: number;
  clothing?: string;
  dancersCrew?: string;
  dancerAffiliateCode: string;
  denominatingDiscipline: number;
  denominatingDisciplineCount: number;
  denominatingDisciplinePoints: number;
  //globalRanking: number;
  //classAttendanceScore: number | null;
  //professionalismBonusScore: number | null;
  //aspNetUsers: AspNetUsers;
  dancerCompetitionPosition: DancerCompetitionPosition[];
  dancerExperiences: DancerExperiences[];
  dancerSeasonInfo: DancerSeasonInfo[];

  deceasedDate?: Date;
  ripmessage?: string;
  rankingDancerEventDetails: RankingDancerEventDetails[];
  rankingDancerDetails: RankingDancerDetails[];
  // Added afterwards : position - for those with shared position
  position?: number;

  facebookLogin: string;
  instagramLogin: string;
  twitterLogin: string;
  tiktokLogin: string;
}

export interface Rewards {
  sponsorsipId: number;
  aspNetUsersId: Guid | null;
  seasonStart: number | null;
  subscriptionPaymentDate: Date;
  subscriptionAmount: number | null;
  currencyId: number | null;
  currency: string | null;
  sponsorType: number | null; // tinyint
  sponsorTypeDescription: string | null;
  sponsorName: string | null;
  dancerAffiliateCode: string;
}

export interface RewardInformation {
  hasDancerNames: boolean;
  sponsorGroupAmounts: { [key: string]: number };
  // rankAndName split 1�Bobby
  allocations: { [key: string]: { [rankAndName: string]: number } };
}

export interface Disciplines {
  id: number;
  discipline: string;
  competitions: Competitions[];
}
export interface Currencies {
  currencyId: number;
  code: string;
  name: string;
  battlePoints: BattlePoints[];
}
//export interface Awards {
//    awardId: number;
//    currencyId: number | null;
//    awardTitle: string;
//    positionStart: number | null;
//    positionEnd: number | null;
//    awardMoneyPrice: number | null;
//    currency: Currencies;
//    competitionAward: CompetitionAward[];
//    awardPoints: number | null;
//}

export interface BattlePoints {
  battlePointId: number;
  positionStart: number | null;
  positionEnd: number | null;
  creationDate: Date;
  modificationDate: Date;
}
export interface DancerCompetitionPosition {
  dancerCompetitionId: number;
  dancerId: number;
  positionAchieved: number;
  competitionId: number;
  calculatedScore: number;
  competition?: Competitions;
  dancer?: Dancers;
}
export interface CompetitionBattlePoints {
  competitionId: number;
  battlePointId: number;
  battlePoint?: BattlePoints;
  competition?: Competitions;
}

export interface Competitions {
  competitionId: number;
  eventId: number;
  disciplineId: number;
  categoryId: number | null;
  displayName: string;
  startDate: Date | null;
  endDate: Date | null;
  status: number;
  description: string;
  competitorCount: number | null;
  category?: Categories;
  discipline?: Disciplines;
  event?: Events;
  competitionAward?: CompetitionBattlePoints[];
  dancerCompetitionPosition?: DancerCompetitionPosition[];
}

export interface Events {
  /*[Key]*/
  eventId: number;
  /*[Required]
                [StringLength(100)]*/
  eventName: string;
  eventStature: number;

  eBattlesOnly: boolean;
  eventCapacity?: number;
  eventDigitalStrength?: number;

  /*[Required]
                [StringLength(2)]*/
  countryCode: string;
  /*[Required]
                [StringLength(200)]*/
  eventAddress1: string;
  /*[StringLength(200)]*/
  eventAddress2?: string;
  /*[Required]
                [StringLength(50)]*/
  eventCity: string;
  /*[Required]
                [StringLength(10)]*/
  eventPostalCode: string;
  //eventGeography: Geometry;
  organisationId: number;
  creationDate: Date;
  modificationDate?: Date;
  /*[Column(TypeName = "smalldatetime")]*/
  eventStartDate: Date;
  /*[StringLength(200)]*/
  eventBannerImage?: string;
  /*[Column(TypeName = "smalldatetime")]*/
  eventEndDate?: Date;
  /*[StringLength(300)]*/
  eventExternalUrl?: string;
  /*[StringLength(50)]*/
  eventVenue: string;
  facebookScore?: number | null;
  instagramScore?: number | null;
  twitterScore?: number | null;
  youtube?: number;
  isOnlineEvent?: boolean;
  eventDescription?: string;
  /*[Column(TypeName = "smalldatetime")]*/
  eventPublicationDate?: Date;
  /*[Column(TypeName = "smalldatetime")]*/
  eventRegistrationStartDate?: Date;
  /*[Column(TypeName = "smalldatetime")]*/
  eventRegistrationEndDate?: Date;
  /*[Column("EventRegistrationURL")]
                [StringLength(200)]*/
  eventRegistrationUrl?: string;
  /*[Column("EventTicketingURL")]
                [StringLength(200)]*/
  eventTicketingUrl?: string;
  /*[StringLength(50)]*/
  facebookLogin?: string;
  /*[StringLength(50)]*/
  instagramLogin?: string;
  /*[StringLength(50)]*/
  twitterLogin?: string;
  /*[StringLength(50)]*/
  youTubeLogin?: string;
  youTubeScore?: number;
  /*[ForeignKey(nameof(OrganisationId))]
                [InverseProperty(nameof(Organisations.Events))]*/
  organisation?: Organisations;
  /*[InverseProperty("Event")]*/
  competitions?: Competitions[];

  eventTotalPoints?: number;

  eventYearsExistance?: number;
  eventDifficulty?: number;
  eventNumberClassicBattles?: number;
}

export const emptyEvent: Events = {
  countryCode: '',
  eBattlesOnly: false,
  eventAddress1: '',
  eventName: '',
  eventAddress2: '',
  //eventAttending: 0,
  eventCapacity: 0,
  eventCity: '',
  eventBannerImage: '',
  eventPostalCode: '',
  eventId: -1,
  eventVenue: '',
  eventStature: 0,
  eventDigitalStrength: 0,
  organisationId: -1,
  eventStartDate: new Date(),
  eventExternalUrl: '',
  creationDate: new Date(),
  eventDescription: '',
  eventEndDate: new Date(),
  eventPublicationDate: new Date()
};
export interface Organisations {
  aspNetUsersId: Guid;
  organisationName: string;
  organisationId: number;
  countryCode: string;
  organisationAddress1: string;
  organisationAddress2: string;
  organisationCity: string;
  organisationPostalCode: string;
  organisationDescription: string;
  organiserFullName: string;
  organiserEmail: string;
  organiserLogo: string;
  organisationUrl: string;
}
export const emptyOrg: Organisations = {
  countryCode: '',
  organisationAddress1: '',
  organisationAddress2: '',
  organisationCity: '',
  organisationName: '',
  organisationPostalCode: '',
  organisationId: -1,
  aspNetUsersId: '',
  organisationDescription: '',
  organiserFullName: '',
  organiserEmail: '',
  organiserLogo: '',
  organisationUrl: ''
};

export const jsonPostOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Accept-Encoding': 'gzip, deflate, br'
  }
};

export class Service {
  protected cacheKey;
  protected lastError;
  protected errors: any[] = [];

  protected errorHandler = (err) => {
    this.errors.push(err);
    this.lastError = err;
    console.log(err);
  };

  protected async Single<T>(url: string): Promise<T | void> {
    var o = await fetch(url)
      .then((rs) => rs.json())
      .then((o) => o as T)
      .catch((err) => {
        this.errorHandler(err);
        return {} as T;
      });
    return o;
  }

  protected async Get<T>(url: string): Promise<T[]> {
    var o = await fetch(url)
      .then((rs) => rs.json())
      .then((o) => o as T[])
      .catch((err) => {
        this.errorHandler(err);
        return [] as T[];
      });
    return o;
  }

  protected async Post<TOutput, TInput>(url: string, body: TInput): Promise<TOutput | void> {
    var o = await fetch(url, { ...jsonPostOptions, body: JSON.stringify(body) })
      .then((rs) => rs.json())
      .then((o) => o as TOutput)
      .catch((err) => {
        this.errorHandler(err);
        return {} as TOutput;
      });
    return o;
  }
}

/*
  https://gist.github.com/jonleighton/958841
MIT LICENSE
Copyright 2011 Jon Leighton
Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files(the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and / or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/

function base64ArrayBuffer(arrayBuffer) {
  var base64 = '';
  var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  var bytes = new Uint8Array(arrayBuffer);
  var byteLength = bytes.byteLength;
  var byteRemainder = byteLength % 3;
  var mainLength = byteLength - byteRemainder;

  var a, b, c, d;
  var chunk;

  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }

  return base64;
}

function base64ToArrayBuffer(base64): ArrayBufferLike {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
const MSGPACK_TYPE = 'application/x-msgpack';

export class CachedService<T> extends Service {
  constructor(cacheKey: string) {
    super();
    this.cacheKey = cacheKey;
  }

  protected async GetCached(url: string, forceRefresh: boolean = false): Promise<T[]> {
    // | void
    let cacheJson: string | null;
    let cachedValue: T[] | void | undefined = undefined;
    if ((cacheJson = sessionStorage.getItem(this.cacheKey + url))) {
      if (!forceRefresh) {
        cachedValue = JSON.parse(cacheJson) as T[];
        return cachedValue;
      }
    }

    var task = fetch(url, {
      headers: { 'Accept-Encoding': 'gzip, deflate, br' },
      method: 'get'
    })
      .then((rs) => rs.json())
      .then((o) => {
        const v = o as T[];
        sessionStorage.setItem(this.cacheKey + url, JSON.stringify(v));
        return v;
      })
      .catch(this.errorHandler);

    if (cachedValue) return cachedValue;

    var t = await task;
    return t || [];
  }

  protected async GetCachedProcessed(
    url: string,
    forceRefresh: boolean,
    preSaveProcess: (item: T) => any,
    postLoadProcess: (item: any) => T,
    isJson: boolean = true,
    saveToSession: boolean = true
  ): Promise<T[]> {
    // | void
    let cacheValue: string | null;
    let cachedValue: T[] | void | undefined = undefined;
    if ((cacheValue = sessionStorage.getItem(this.cacheKey + url))) {
      if (!forceRefresh) {
        if (isJson) {
          cachedValue = JSON.parse(cacheValue) as [];
          return cachedValue.map(postLoadProcess);
        } else {
          let bytes = base64ToArrayBuffer(cacheValue);
          let obj = decode(bytes) as any[];
          return [postLoadProcess(obj[0])];
        }
      }
    }

    var task = fetch(url, {
      headers: {
        'Accept-Encoding': 'gzip, deflate, br',
        Accept: '*/*'
      },
      method: 'get'
    })
      .then((rs) => {
        const contentType = rs.headers.get('Content-Type');
        if (contentType && contentType.startsWith(MSGPACK_TYPE) && rs.body != null) {
          return rs.arrayBuffer();
        }
        return rs.json();
      })
      .then((o) => {
        if (isJson) {
          const v = o as T[];
          if (saveToSession) {
            const json = JSON.stringify(v.map(preSaveProcess));
            sessionStorage.setItem(this.cacheKey + url, json);
          }
          return v;
        } else {
          let bytes = o as ArrayBuffer;

          if (saveToSession) {
            sessionStorage.setItem(this.cacheKey + url, base64ArrayBuffer(bytes));
          }
          var object = decode(bytes) as any[];
          return [postLoadProcess(object[0])];
        }
      })
      .catch(this.errorHandler);

    if (cachedValue) return cachedValue;

    var t = await task;
    return t || [];
  }

  protected async GetCachedOf<Type>(
    url: string,
    forceRefresh: boolean = false,
    postProcess?: (items: Type) => Type
  ): Promise<Type | null> {
    // | void
    let cacheJson: string | null;
    let cachedValue: Type | void | undefined = undefined;
    if ((cacheJson = sessionStorage.getItem(this.cacheKey + url))) {
      if (!forceRefresh) {
        cachedValue = JSON.parse(cacheJson) as Type;
        return cachedValue;
      }
    }

    var task = fetch(url, {
      headers: { 'Accept-Encoding': 'gzip, deflate, br' },
      method: 'get'
    })
      .then((rs) => rs.json())
      .then((o) => {
        const v = !!postProcess ? postProcess(o as Type) : (o as Type);
        sessionStorage.setItem(this.cacheKey + url, JSON.stringify(v));
        return v;
      })
      .catch(this.errorHandler);

    if (cachedValue) return cachedValue;

    var t = await task;
    return t || null;
  }
}
