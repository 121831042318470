import { Organisations } from './types';

class OrgService {
  async List() {
    var obj = (await fetch('/organisations/list').then((rs) => rs.json())) as Organisations[];
    return obj;
  }

  async Update(obj: Organisations) {
    await fetch('/organisations/update', {
      method: 'post',
      //headers: {'Content-Type':'application/x-www-form-urlencoded'}, // this line is important, if this content-type is not set it wont work
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj)
    });
  }
}

export const instance = new OrgService();
export default instance;
