import { Events, CachedService, jsonPostOptions } from './types';

class EventService extends CachedService<Events> {
  Delete = async (editingEventId: number | null | undefined) => {
    try {
      await fetch('/events/delete', {
        ...jsonPostOptions,
        redirect: 'error',
        body: JSON.stringify({ eventId: editingEventId })
      });

      return true;
    } catch (err) {
      return false;
    }
  };

  List = async (OrgId: number) => this.Get<Events>('/events/list?OrgId=' + OrgId);

  Update(obj: Events): Promise<Response> {
    if (obj.eventId === -1) {
      return fetch('/events/insert', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
      });
    } else {
      return fetch('/events/update', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj)
      });
    }
  }

  DancerCountRanges = () => [1, 50, 100, 200, 500];
  WelcomeCapacityRanges = () => [1, 100, 500, 1000, 5000, 10000];
  DigitalStrengthRanges = () => [1, 5000, 10000, 50000, 100000];

  // dancer experience ranges
  DancerShowDigitalStrengthRanges = () => [50000, 100000, 500000, 1000000];
  ArtistProducerDigitalStrengthRanges = () => [100000, 500000, 1000000, 5000000, 10000000];
}

export const instance = new EventService('ck_evtSrv');

export default instance;
