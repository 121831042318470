import { Competitions, DancerCompetitionPosition, Events, Service } from '../components/admin/services';

class PublicEventService extends Service {
  async Find(filters: {}) {
    var obj = (await fetch('/events/publicFind', {
      method: 'post',
      headers: { 'Content-Type': 'application/json', Accepts: 'application/json' },
      body: JSON.stringify(filters)
    }).then((rs) => rs.json())) as Events[];
    return obj;
  }
  async ListCompetitions(organisationId: number, eventId: number) {
    var obj = (await fetch(`/competitions/list?OrgId=${organisationId}&EventId=${eventId}`).then((rs) =>
      rs.json()
    )) as Competitions[];
    return obj;
  }
  async List(OrgId: number) {
    var obj = (await fetch('/events/list?OrgId=' + OrgId).then((rs) => rs.json())) as Events[];
    return obj;
  }

  async GetEvent(eventId: number) {
    var obj = (await fetch('/events/get?EvtId=' + eventId).then((rs) => rs.json())) as Events;
    return obj;
  }

  async ListEventDancerPositions(eventId: number) {
    var obj = (await fetch(`/events/positions?EventId=${eventId}`).then((rs) =>
      rs.json()
    )) as DancerCompetitionPosition[];
    return obj;
  }

  async AddDancerCompetitionPosition(newValue: DancerCompetitionPosition) {
    var obj = (await this.Post<number, DancerCompetitionPosition>(`/events/positions/new`, newValue)) as number;
    return obj;
  }

  async RemoveDancerCompetitionPosition(newValue: DancerCompetitionPosition) {
    var obj = (await this.Post<number, DancerCompetitionPosition>(`/events/positions/remove`, newValue)) as number;
    return obj;
  }
}

export const instance = new PublicEventService();
export default instance;

export interface EventFilter {
  name?: string;
  fromDate?: Date;
  toDate?: Date;
  totalPrize?: number;
  grandPrize?: number;
  category?: string;
  discipline?: string;
  season?: number;
}
