import { Component } from 'react';
//import React, { Component, Fragment } from 'react';
import { Nav } from 'react-bootstrap';
//import authService from './AuthorizeService';
import authService from './AuthService';
import { ApplicationPaths } from './ApiAuthorizationConstants';

/* eslint react/react-in-jsx-scope: "off" */
export class LoginMenuTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null,
      loaded: false
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()]);
    this.setState({
      isAuthenticated,
      userName: user && user.name,
      loaded: true
    });
  }

  render() {
    const { isAuthenticated, userName, loaded } = this.state;
    if (!loaded) {
      return (
        <Nav.Item>
          <span>Loading...</span>
        </Nav.Item>
      );
    }
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      //const loginPath = `${ApplicationPaths.Login}`;
      const loginPath = `${ApplicationPaths.LoginFull}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
      return this.authenticatedView(userName, profilePath, logoutPath);
    }
  }

  logOut = async () => {
    await authService.signOut();
  };

  authenticatedView(userName, profilePath, logoutPath) {
    return (
      <>
        <Nav.Item className={'align-self-center' + this.props.className}>
          <Nav.Link href={profilePath}>
            <div className="text-center align-middle" title={'View ' + userName + "'s Profile"}>
              {this.props.stacked ? (
                <>
                  <i className="fa fa-user d-block" />
                  <span>{userName}</span>
                </>
              ) : (
                <span className="d-none d-md-block">
                  <i className="fa fa-user" /> {userName}
                </span>
              )}
            </div>
          </Nav.Link>
        </Nav.Item>
      </>
    );
  }

  anonymousView(registerPath, loginPath) {
    return (
      <>
        <Nav.Item className={'align-self-center' + this.props.className}>
          <Nav.Link href={loginPath}>
            <div className="text-center align-middle" title="Login">
              {/*fa-sign-in*/}
              {this.props.stacked ? (
                <>
                  <i className="fa fa-user d-block" />
                  <span>Login</span>
                </>
              ) : (
                <span className="d-none d-md-block">
                  <i className="fa fa-user" /> Login
                </span>
              )}
            </div>
          </Nav.Link>
        </Nav.Item>
      </>
    );
  }
}
